<template>
    <div class="content">
        <BreadCrumbs :elements="[{ link: 'Blog', name: 'Blog' }]" />
        <section class="articleSection">
            <div class="container">
                <div class="headline">
                    <h1>Cikkek</h1>
                    <hr />
                    <div class="container" v-if="model && model.meta">
                        <div class="row">
                            <div class="col-md-12">
                                <ul
                                    class="pagination justify-content-center my-4"
                                >
                                    <template
                                        v-for="(item, index) in model.meta
                                            .links"
                                    >
                                        <li
                                            class="page-item"
                                            v-if="
                                                item.label ===
                                                    '&laquo; Previous'
                                            "
                                            v-bind:class="{
                                                disabled: item.url === null
                                            }"
                                            v-bind:key="
                                                'pagination-prev-' + index
                                            "
                                        >
                                            <a
                                                class="page-link"
                                                style="cursor: pointer"
                                                v-on:click="minusPage"
                                                aria-label="Previous"
                                            >
                                                <span aria-hidden="true"
                                                    >&laquo;</span
                                                >
                                            </a>
                                        </li>
                                        <li
                                            class="page-item"
                                            v-bind:class="{
                                                disabled: item.url === null
                                            }"
                                            v-else-if="
                                                item.label === 'Next &raquo;'
                                            "
                                            v-bind:key="
                                                'pagination-next-' + index
                                            "
                                        >
                                            <a
                                                class="page-link"
                                                style="cursor: pointer"
                                                v-on:click="plusPage"
                                                aria-label="Next"
                                            >
                                                <span aria-hidden="true"
                                                    >&raquo;</span
                                                >
                                            </a>
                                        </li>
                                        <li
                                            class="page-item"
                                            v-bind:class="{
                                                active: item.active
                                            }"
                                            v-else
                                            v-bind:key="'pagination-' + index"
                                        >
                                            <a
                                                class="page-link"
                                                style="cursor: pointer"
                                                v-on:click="setPage(item.label)"
                                                >{{ item.label }}</a
                                            >
                                        </li>
                                    </template>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <!--<div class="filterIcons">
                        <i class="icon-menu"></i>
                        <i class="icon-nezet_2"></i>
                        <i class="icon-filter"></i>
                    </div>-->
                </div>
                <div class="row">
                    <template v-for="(item, index) in model.data">
                        <BlogRow v-bind:key="'blog-' + index" :blog="item" />
                    </template>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import BlogRow from "@/components/BlogRow";
import BreadCrumbs from "@/components/BreadCrumbs";

export default {
    name: "Blog",
    components: { BreadCrumbs, BlogRow },
    data() {
        return {
            model: {},
            page: 1,
            sortBy: "price_desc",
            comparList: []
        };
    },
    computed: {
        url: function() {
            return process.env.VUE_APP_API_URL;
        }
    },
    beforeMount() {
        if (this.$route.query.page) {
            this.page = this.$route.query.page;
        }
        if (this.$route.query.sortBy) {
            this.sortBy = this.$route.query.sortBy;
        }
        this.getSearchData();
    },
    watch: {
        $route: {
            handler: function() {
                if (this.$route.query.page) {
                    this.page = this.$route.query.page;
                }
                if (this.$route.query.sortBy) {
                    this.sortBy = this.$route.query.sortBy;
                }
                this.getSearchData();
            },
            deep: true
        }
    },
    methods: {
        setCompareId(data) {
            if (data.checked) {
                this.comparList.push(data.id);
            } else {
                const index = this.comparList.indexOf(data.id);
                if (index > -1) {
                    this.comparList.splice(index, 1); // 2nd parameter means remove one item only
                }
            }
        },
        setSortBy() {
            let arr = JSON.parse(JSON.stringify(this.$route.query));
            arr["sortBy"] = this.sortBy;
            this.$router
                .push({
                    name: "Blog",
                    params: this.$route.params,
                    query: arr
                })
                .catch(() => {});
        },
        minusPage() {
            this.page = parseInt(this.page) - 1;
            this.setPage(this.page);
        },
        plusPage() {
            this.page = parseInt(this.page) + 1;
            this.setPage(this.page);
        },
        setPage(pg) {
            this.page = pg;
            let arr = JSON.parse(JSON.stringify(this.$route.query));
            arr["page"] = this.page;
            this.$router
                .push({
                    name: "Blog",
                    params: this.$route.params,
                    query: arr
                })
                .catch(() => {});
        },
        getSearchData() {
            let arr = JSON.parse(JSON.stringify(this.$route.query));
            arr["page"] = this.page;
            arr["sortBy"] = this.sortBy;

            ApiService.get(
                `${this.url}/blog/get-list?${this.buildQueryString(arr)}`
            ).then(response => {
                this.model = response.data;
            });
        }
    }
};
</script>

<style scoped></style>
